<template>
  <v-container fluid>
    <v-row wrap>
      <v-col cols="12">
        <h2>Informações</h2>
      </v-col>
      <!-- foto -->
      <v-col cols="12" sm="2" md="2">
        <v-avatar size="66">
          <v-img :src="renderUserPhoto"></v-img>
        </v-avatar>
      </v-col>
      <v-col cols="12" sm="10" md="10">
        <div
          v-if="typeof usuario.foto == 'string'"
          class="d-flex flex-row align-center justify-start"
        >
          <p class="ma-0 pa-0 text-truncate">{{ usuario.foto }}</p>
          <v-btn @click="usuario.foto = null" icon class="ml-2">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-file-input
          v-else
          accept="image/png, image/jpeg, image/bmp"
          placeholder="Colocar foto"
          prepend-icon="mdi-camera"
          v-model="usuario.foto"
          label="Foto"
        ></v-file-input>
      </v-col>
      <!-- nome -->
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          ref="nome"
          label="Nome"
          placeholder="Escreva o nome do usuário"
          required
          outlined
          dense
          v-model="usuario.nome"
          data-vv-as="nome"
          persistent-hint
          :hint="errors.first('adm_usuarios.nome')"
          :error="errors.collect('adm_usuarios.nome').length ? true : false"
          v-validate="'required|min:3'"
          data-vv-scope="adm_usuarios"
          data-vv-validate-on="change"
          id="nome"
          name="nome"
        ></v-text-field>
      </v-col>
      <!-- email -->
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          ref="email"
          label="E-mail"
          placeholder="Escreva o email do usuário"
          required
          outlined
          dense
          v-model="usuario.email"
          data-vv-as="email"
          persistent-hint
          :hint="errors.first('adm_usuarios.email')"
          :error="errors.collect('adm_usuarios.email').length ? true : false"
          v-validate="'required|email'"
          data-vv-scope="adm_usuarios"
          data-vv-validate-on="change"
          id="email"
          name="email"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateFormattedMomentjs"
              label="Data de aniversário"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
              data-vv-as="data de aniversário"
              persistent-hint
              :hint="errors.first('adm_usuarios.data_aniversario')"
              :error="
                errors.collect('adm_usuarios.data_aniversario').length
                  ? true
                  : false
              "
              v-validate="'required'"
              data-vv-scope="adm_usuarios"
              data-vv-validate-on="change|blur|input"
              id="data_aniversario"
              name="data_aniversario"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="usuario.aniversario"
            :active-picker.sync="activePicker"
            :max="
              new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10)
            "
            min="1950-01-01"
            @change="save"
            locale="pt-br"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <!-- filial -->
      <v-col cols="12" sm="6" md="6">
        <v-select
          ref="filial"
          label="Filial"
          placeholder="Selecione Filial do usuário"
          :items="filiais"
          dense
          outlined
          clearable
          v-model="usuario.relacionamento.id_filial"
          item-text="nome"
          item-value="id"
          id="filial"
          name="filial"
          :loading="loadings.loadingFilial"
        ></v-select>
      </v-col>
      <!-- setor -->
      <v-col cols="12" sm="6" md="6">
        <v-select
          ref="setor"
          label="Setor"
          placeholder="Selecione Setor do usuário"
          :items="setores"
          dense
          outlined
          clearable
          v-model="usuario.relacionamento.id_setor"
          item-text="nome"
          item-value="id"
          id="setor"
          name="setor"
          :loading="loadings.loadingSetor"
        ></v-select>
      </v-col>
      <!-- cargo -->
      <v-col cols="12" sm="6" md="6">
        <v-select
          ref="cargo"
          label="Cargo"
          placeholder="Selecione Cargo do usuário"
          :items="cargos"
          dense
          outlined
          v-model="usuario.relacionamento.id_cargo"
          item-text="nome"
          item-value="id"
          data-vv-as="cargo"
          persistent-hint
          :hint="errors.first('adm_usuarios.cargo')"
          :error="errors.collect('adm_usuarios.cargo').length ? true : false"
          v-validate="'required'"
          data-vv-scope="adm_usuarios"
          data-vv-validate-on="change"
          id="cargo"
          name="cargo"
          :loading="loadings.loadingCargo"
        ></v-select>
      </v-col>
      <!-- Time -->
      <v-col cols="12" sm="6" md="6">
        <v-select
          ref="time"
          label="time"
          placeholder="Selecione time do usuário"
          :items="times"
          dense
          outlined
          v-model="usuario.relacionamento.id_time"
          item-text="nome"
          item-value="id"
          clearable
          :loading="loadings.loadingTime"
        ></v-select>
      </v-col>
      <!-- papel -->
      <v-col cols="12" sm="6" md="6">
        <v-select
          ref="papel"
          label="Papel"
          placeholder="Selecione papel do usuário"
          :items="papeis"
          dense
          outlined
          v-model="usuario.id_papel"
          item-text="name"
          item-value="id"
          clearable
          :loading="loadings.loadingPapel"
        ></v-select>
      </v-col>
      <!-- é gestor? -->
      <v-col cols="12" sm="12" md="6">
        <v-switch
          class="mt-0"
          v-model="usuario.gestor"
          :disabled="usuario.coordenador == true ? true : false"
          @change="gestor(usuario.gestor)"
          label="Esse usuário é gestor?"
          :true-value="1"
          :false-value="0"
        ></v-switch>
      </v-col>
      <!-- é coordenador? -->
      <v-col cols="12" sm="12" md="6">
        <v-switch
          class="mt-0"
          v-model="usuario.coordenador"
          :disabled="usuario.gestor == true ? true : false"
          @change="coordenador(usuario.coordenador)"
          label="Esse usuário é coordenador?"
          :true-value="1"
          :false-value="0"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h2>Endereço</h2>
      </v-col>
      <!-- cep -->
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          ref="cep"
          label="Cep"
          placeholder="digite o cep"
          required
          outlined
          dense
          v-model="usuario.endereco.cep"
          data-vv-as="cep"
          persistent-hint
          :hint="errors.first('adm_usuarios.cep')"
          :error="errors.collect('adm_usuarios.cep').length ? true : false"
          v-validate="'required'"
          data-vv-scope="adm_usuarios"
          data-vv-validate-on="change|blur|input"
          id="cep"
          name="cep"
          @blur="searchCep()"
          v-mask="['#####-###']"
          :loading="loadings.loadingCep"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          ref="uf"
          label="Uf"
          placeholder="digite a uf"
          required
          outlined
          dense
          v-model="usuario.endereco.uf"
          data-vv-as="uf"
          persistent-hint
          :hint="errors.first('adm_usuarios.uf')"
          :error="errors.collect('adm_usuarios.uf').length ? true : false"
          v-validate="'required'"
          data-vv-scope="adm_usuarios"
          data-vv-validate-on="change|blur|input"
          id="uf"
          name="uf"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          ref="cidade"
          label="Cidade"
          placeholder="digite a cidade"
          required
          outlined
          dense
          v-model="usuario.endereco.cidade"
          data-vv-as="cidade"
          persistent-hint
          :hint="errors.first('adm_usuarios.cidade')"
          :error="errors.collect('adm_usuarios.cidade').length ? true : false"
          v-validate="'required'"
          data-vv-scope="adm_usuarios"
          data-vv-validate-on="change|blur|input"
          id="cidade"
          name="cidade"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          ref="bairro"
          label="Bairro"
          placeholder="digite a bairro"
          required
          outlined
          dense
          v-model="usuario.endereco.bairro"
          data-vv-as="bairro"
          persistent-hint
          :hint="errors.first('adm_usuarios.bairro')"
          :error="errors.collect('adm_usuarios.bairro').length ? true : false"
          v-validate="'required'"
          data-vv-scope="adm_usuarios"
          data-vv-validate-on="change|blur|input"
          id="bairro"
          name="bairro"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          ref="rua"
          label="Rua"
          placeholder="digite a rua"
          required
          outlined
          dense
          v-model="usuario.endereco.rua"
          data-vv-as="rua"
          persistent-hint
          :hint="errors.first('adm_usuarios.rua')"
          :error="errors.collect('adm_usuarios.rua').length ? true : false"
          v-validate="'required'"
          data-vv-scope="adm_usuarios"
          data-vv-validate-on="change|blur|input"
          id="rua"
          name="rua"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          ref="numero"
          label="Número"
          placeholder="digite a numero"
          required
          outlined
          dense
          type="number"
          v-model="usuario.endereco.numero"
          data-vv-as="número"
          persistent-hint
          :hint="errors.first('adm_usuarios.numero')"
          :error="errors.collect('adm_usuarios.numero').length ? true : false"
          v-validate="'required'"
          data-vv-scope="adm_usuarios"
          data-vv-validate-on="change|blur|input"
          id="numero"
          name="numero"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          ref="complemento"
          label="Complemento"
          placeholder="digite a complemento"
          required
          outlined
          dense
          v-model="usuario.endereco.complemento"
          data-vv-as="complemento"
          persistent-hint
          :hint="errors.first('adm_usuarios.complemento')"
          :error="
            errors.collect('adm_usuarios.complemento').length ? true : false
          "
          v-validate="'required'"
          data-vv-scope="adm_usuarios"
          data-vv-validate-on="change|blur|input"
          id="complemento"
          name="complemento"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import userNotImage from "@/assets/images/avatars/user_not_image.png";
import axios from "@axios";
import moment from "moment";

export default {
  name: "UsuariosForm",
  inject: ["$validator"],
  data() {
    return {
      loadings: {
        loadingCep: false,
        loadingFilial: true,
        loadingSetor: true,
        loadingCargo: true,
        loadingPapel: true,
        loadingTime: true,
      },
      showPassword: false,
      confirmPassword: "",
      userNotImage: userNotImage,

      menu: false,
      activePicker: null,
    };
  },
  async created() {
    const dataFiliais = {
      columns: ["id", "nome"],
      validate: [["status", "A"]],
    };
    const dataSetores = {
      columns: ["id", "nome"],
      validate: [["status", "A"]],
    };
    const dataCargos = {
      columns: ["id", "nome"],
      validate: [["status", "A"]],
    };
    const dataTimes = {
      columns: ["id", "nome"],
      validate: [["status", "A"]],
    };

    await this.$store
      .dispatch("filiais/findByColumns", dataFiliais)
      .finally(() => {
        this.loadings.loadingFilial = false;
      });

    await this.$store
      .dispatch("setores/findByColumns", dataSetores)
      .finally(() => {
        this.loadings.loadingSetor = false;
      });

    await this.$store
      .dispatch("cargos/findByColumns", dataCargos)
      .finally(() => {
        this.loadings.loadingCargo = false;
      });

    await this.$store.dispatch("times/findByColumns", dataTimes).finally(() => {
      this.loadings.loadingTime = false;
    });

    await this.$store.dispatch("papeis/initialize").finally(() => {
      this.loadings.loadingPapel = false;
    });
  },
  methods: {
    /**
     * @description Busca pelo cep utilizando o viaCep.
     */
    searchCep() {
      if (this.usuario.endereco.cep.length > 7) {
        this.loadings.loadingCep = true;
        const cepFiltred = this.usuario.endereco.cep.replace("-", "");

        axios
          .get(`https://viacep.com.br/ws/${cepFiltred}/json/`)
          .then((response) => {
            this.usuario.endereco.cep = response.data.cep;
            this.usuario.endereco.rua = response.data.logradouro;
            this.usuario.endereco.bairro = response.data.bairro;
            this.usuario.endereco.complemento = response.data.complemento;
            this.usuario.endereco.uf = response.data.uf;
            this.usuario.endereco.cidade = response.data.localidade;
          })
          .catch(() => {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem: "CEP não encontrado, tente outro.",
              cor: "red",
            });
          })
          .finally(() => {
            this.loadings.loadingCep = false;
          });
      }
    },
    save(date) {
      this.$refs.menu.save(date);
    },
  },
  computed: {
    usuario: {
      get() {
        return this.$store.getters["usuarios/getUsuario"];
      },
      set(value) {
        this.$store.dispatch("usuarios/setUsuario", value);
      },
    },
    filiais: {
      get() {
        return this.$store.getters["filiais/getFiliais"];
      },
    },
    setores: {
      get() {
        return this.$store.getters["setores/getSetores"];
      },
    },
    cargos: {
      get() {
        return this.$store.getters["cargos/getCargosOfByColumns"];
      },
    },
    papeis: {
      get() {
        return this.$store.getters["papeis/getPapeis"];
      },
    },
    times: {
      get() {
        return this.$store.getters["times/getTimes"];
      },
    },
    /**
     * @description Renderiza a imagem na tag v-img independente de ser string ou objeto
     */
    renderUserPhoto() {
      if (
        this.usuario.foto == null ||
        (typeof this.usuario.foto != "string" &&
          typeof this.usuario.foto != "object")
      )
        return this.userNotImage;

      if (typeof this.usuario.foto == "string") {
        return this.usuario.foto;
      }

      const imagePreviewURL = URL.createObjectURL(this.usuario.foto);
      URL.revokeObjectURL(this.usuario.foto);

      return imagePreviewURL;
    },
    computedDateFormattedMomentjs() {
      return this.usuario.aniversario
        ? moment(this.usuario.aniversario).format("DD/MM/YYYY")
        : "";
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
};
</script>

<style></style>
