<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="usuario.foto ? '' : 'primary'"
            :class="usuario.foto ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <v-img v-if="usuario.foto" :src="usuario.foto"></v-img>
            <span v-else class="font-weight-semibold text-5xl">
              {{ avatarText(usuario.nome) }}
            </span>
          </v-avatar>

          <span class="mb-2">{{ usuario.nome }}</span>

          <v-chip
            label
            small
            :color="resolveUserRoleVariant(usuario.papel)"
            :class="`v-chip-light-bg text-sm font-weight-semibold ${resolveUserRoleVariant(
              usuario.papel
            )}--text text-capitalize`"
          >
            {{ usuario.papel }}
          </v-chip>
        </v-card-title>

        <v-card-text class="d-flex justify-center flex-wrap mt-2 pe-sm-0">
          <div class="d-flex align-center me-8 mb-4">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  v-bind="attrs"
                  v-on="on"
                  size="40"
                  rounded
                  color="primary"
                  class="v-avatar-light-bg primary--text me-3"
                >
                  <v-icon color="primary" size="22">
                    mdi-briefcase-variant-outline
                  </v-icon>
                </v-avatar>
              </template>
              <span>Setor</span>
            </v-tooltip>

            <div>
              <span>
                {{
                  usuario.setor && usuario.setor.nome
                    ? usuario.setor.nome
                    : "Não possui"
                }}
              </span>
            </div>
          </div>

          <div class="d-flex align-center mb-4 me-4">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  v-bind="attrs"
                  v-on="on"
                  size="40"
                  rounded
                  color="primary"
                  class="v-avatar-light-bg primary--text me-3"
                >
                  <v-icon color="primary" size="22">
                    mdi-badge-account-horizontal-outline
                  </v-icon>
                </v-avatar>
              </template>
              <span>Cargo</span>
            </v-tooltip>

            <div>
              <span>
                {{
                  usuario.cargo && usuario.cargo.nome
                    ? usuario.cargo.nome
                    : "Não possui"
                }}
              </span>
            </div>
          </div>
        </v-card-text>

        <v-expand-transition>
          <v-card-text v-show="trueDetails">
            <h2 class="text-xl font-weight-semibold mb-2">Detalhes</h2>

            <v-divider></v-divider>

            <v-list>
              <v-list-item dense class="px-0 mb-n2">
                <span class="font-weight-medium me-2">Nome:</span>
                <span class="text--secondary">{{ usuario.nome }}</span>
              </v-list-item>

              <v-list-item dense class="px-0 mb-n2">
                <span class="font-weight-medium me-2">Filial:</span>
                <span
                  v-if="
                    usuario.relacionamento &&
                    usuario.relacionamento.filial &&
                    usuario.relacionamento.filial.nome
                  "
                  class="text--secondary"
                >
                  {{ usuario.relacionamento.filial.nome }}
                </span>
                <v-chip v-else small color="warning">
                  <v-icon class="mr-1">mdi-information-outline</v-icon> Sem
                  filial
                </v-chip>
              </v-list-item>

              <v-list-item dense class="px-0 mb-n2">
                <span class="font-weight-medium me-2">Setor:</span>
                <span
                  v-if="
                    usuario.relacionamento &&
                    usuario.relacionamento.setor &&
                    usuario.relacionamento.setor.nome
                  "
                  class="text--secondary"
                >
                  {{ usuario.relacionamento.setor.nome }}
                </span>
                <v-chip v-else small color="warning">
                  <v-icon class="mr-1">mdi-information-outline</v-icon> Sem
                  setor
                </v-chip>
              </v-list-item>

              <v-list-item dense class="px-0 mb-n2">
                <span class="font-weight-medium me-2">Cargo:</span>
                <span
                  v-if="
                    usuario.relacionamento &&
                    usuario.relacionamento.cargo &&
                    usuario.relacionamento.cargo.nome
                  "
                  class="text--secondary"
                >
                  {{ usuario.relacionamento.cargo.nome }}
                </span>
                <v-chip v-else small color="warning">
                  <v-icon class="mr-1">mdi-information-outline</v-icon> Sem
                  cargo
                </v-chip>
              </v-list-item>

              <v-list-item dense class="px-0 mb-n2">
                <span class="font-weight-medium me-2">Time:</span>
                <span
                  v-if="
                    usuario.relacionamento &&
                    usuario.relacionamento.time &&
                    usuario.relacionamento.time.nome
                  "
                  class="text--secondary"
                >
                  {{ usuario.relacionamento.time.nome }}
                </span>
                <v-chip v-else small color="warning">
                  <v-icon class="mr-1">mdi-information-outline</v-icon> Sem time
                </v-chip>
              </v-list-item>

              <v-list-item dense class="px-0 mb-n2">
                <span class="font-weight-medium me-2">Gestor:</span>
                <span class="text--secondary">{{
                  resolveUserGestor(usuario.gestor)
                }}</span>
              </v-list-item>
              <v-list-item dense class="px-0 mb-n2">
                <span class="font-weight-medium me-2">Coordenador:</span>
                <span class="text--secondary">{{
                  resolveUserCoordenandor(usuario.coordenador)
                }}</span>
              </v-list-item>

              <v-list-item dense class="px-0 mb-n2">
                <span class="font-weight-medium text-no-wrap me-2"
                  >E-mail:</span
                >
                <span class="text--secondary">{{ usuario.email }}</span>
              </v-list-item>

              <v-list-item dense class="px-0 mb-n2">
                <span class="font-weight-medium me-2">Status:</span>
                <span class="text--secondary">
                  <v-chip
                    small
                    label
                    :color="resolveUserStatusVariant(usuario.status)"
                    :class="`v-chip-light-bg ${resolveUserStatusVariant(
                      usuario.status
                    )}--text font-weight-medium text-capitalize`"
                  >
                    {{ usuario.status == "A" ? "Ativo" : "Inativo" }}
                  </v-chip>
                </span>
              </v-list-item>

              <v-list-item dense class="px-0 mb-n2">
                <span class="font-weight-medium me-2">Papel:</span>
                <span class="text--secondary text-capitalize">{{
                  usuario.papel
                }}</span>
              </v-list-item>
              <v-list-item dense class="px-0 mb-n2">
                <span class="font-weight-medium me-2">Uid:</span>
                <span class="text--secondary">{{ usuario.uuid }}</span>
              </v-list-item>

              <v-list-item dense class="px-0 mb-n2">
                <span class="font-weight-medium me-2">Celular:</span>
                <span v-if="usuario.celular" class="text--secondary">{{
                  usuario.celular
                }}</span>
                <v-chip v-else small color="warning">
                  <v-icon class="mr-1">mdi-information-outline</v-icon> Sem
                  celular
                </v-chip>
              </v-list-item>

              <v-list-item dense class="px-0 mb-n2">
                <span class="font-weight-medium me-2">Data nascimento:</span>
                <span class="text--secondary">{{
                  usuario.aniversario | dateFormatBr()
                }}</span>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-expand-transition>

        <!-- <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            class="me-3"
            @click="isBioDialogOpen = !isBioDialogOpen"
          >
            Editar
          </v-btn>
          <v-btn color="error" outlined> Suspender </v-btn>
        </v-card-actions> -->
      </v-card>

      <!-- edit profile dialog data -->
      <!-- <user-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :usuario="usuario"
      ></user-bio-edit> -->
    </v-col>

    <!-- user plan -->
    <!--<v-col cols="12">
      <v-card class="user-plan" flat>
        <v-card-title class="flex-start align-start justify-space-between pb-0">
          <v-chip
            label
            color="primary"
            small
            class="v-chip-light-bg primary--text font-weight-semibold mb-3 me-3"
          >
            {{ usuario.currentPlan }}
          </v-chip>

          <div class="user-pricing">
            <sup class="primary--text">$</sup>
            <span class="text-5xl font-weight-semibold primary--text">{{
              resolveCurrentPlanValue(usuario.currentPlan)
            }}</span>
            <sub class="text-base font-weight-light">/ month</sub>
          </div>
        </v-card-title>

        <v-card-text>
          <v-list class="pa-0">
            <v-list-item
              v-for="benefit in standardPlan.benefits"
              :key="benefit"
              dense
              class="px-0 mb-n2"
            >
              <v-icon size="10" class="me-2">
                {{ icons.mdiCheckboxBlankCircle }}
              </v-icon>
              <span>{{ benefit }}</span>
            </v-list-item>
          </v-list>

          <p class="d-flex font-weight-semibold text--primary mt-5 mb-2">
            <span>Days</span>
            <v-spacer></v-spacer>
            <span>26 of 30 Days</span>
          </p>
          <v-progress-linear value="86" height="8" rounded></v-progress-linear>
          <p class="mt-2">4 days remaining</p>

          <v-btn
            color="primary"
            block
            @click.stop="$emit('update:is-plan-upgrade-dialog-visible', true)"
          >
            Upgrade Plan
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>-->
  </v-row>
</template>
<script>
// import UserBioEdit from "@/modules/administrativo/modules/empresas/components/usuario/user-bio-panel/UserBioEdit.vue";

export default {
  name: "UserBioPanel",
  // components: { UserBioEdit },
  props: {
    trueDetails: {
      type: Boolean,
      require: false,
      default: true,
    },
  },
  data: () => ({
    // isBioDialogOpen: false,
  }),
  methods: {
    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");

      return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
    },
    resolveUserRoleVariant(role) {
      if (role === "Desenvolvedor") return "primary";
      if (role === "Administrador") return "warning";
      if (role === "Recurso Humanos") return "success";
      if (role === "Gestor") return "info";
      if (role === "Operacional") return "error";
      if (role === "Comercial") return "error";

      return "primary";
    },
    resolveUserStatusVariant(status) {
      if (status === "P") return "warning";
      if (status === "A") return "success";
      if (status === "I") return "secondary";

      return "primary";
    },
    resolveUserGestor(value) {
      if (value === 0) return "Não";
      if (value === 1) return "Sim";

      return "Não";
    },
    resolveUserCoordenandor(value) {
      if (value === 0) return "Não";
      if (value === 1) return "Sim";

      return "Não";
    },
  },
  computed: {
    usuario: {
      get() {
        return this.$store.getters["usuarios/getUsuario"];
      },
    },
  },
};
</script>
