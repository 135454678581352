import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"user-bio-panel"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"pt-10"},[_c(VCardTitle,{staticClass:"justify-center flex-column"},[_c(VAvatar,{staticClass:"mb-4",class:_vm.usuario.foto ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":_vm.usuario.foto ? '' : 'primary',"size":"120","rounded":""}},[(_vm.usuario.foto)?_c(VImg,{attrs:{"src":_vm.usuario.foto}}):_c('span',{staticClass:"font-weight-semibold text-5xl"},[_vm._v(" "+_vm._s(_vm.avatarText(_vm.usuario.nome))+" ")])],1),_c('span',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.usuario.nome))]),_c(VChip,{class:("v-chip-light-bg text-sm font-weight-semibold " + (_vm.resolveUserRoleVariant(
            _vm.usuario.papel
          )) + "--text text-capitalize"),attrs:{"label":"","small":"","color":_vm.resolveUserRoleVariant(_vm.usuario.papel)}},[_vm._v(" "+_vm._s(_vm.usuario.papel)+" ")])],1),_c(VCardText,{staticClass:"d-flex justify-center flex-wrap mt-2 pe-sm-0"},[_c('div',{staticClass:"d-flex align-center me-8 mb-4"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({staticClass:"v-avatar-light-bg primary--text me-3",attrs:{"size":"40","rounded":"","color":"primary"}},'v-avatar',attrs,false),on),[_c(VIcon,{attrs:{"color":"primary","size":"22"}},[_vm._v(" mdi-briefcase-variant-outline ")])],1)]}}])},[_c('span',[_vm._v("Setor")])]),_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.usuario.setor && _vm.usuario.setor.nome ? _vm.usuario.setor.nome : "Não possui")+" ")])])],1),_c('div',{staticClass:"d-flex align-center mb-4 me-4"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({staticClass:"v-avatar-light-bg primary--text me-3",attrs:{"size":"40","rounded":"","color":"primary"}},'v-avatar',attrs,false),on),[_c(VIcon,{attrs:{"color":"primary","size":"22"}},[_vm._v(" mdi-badge-account-horizontal-outline ")])],1)]}}])},[_c('span',[_vm._v("Cargo")])]),_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.usuario.cargo && _vm.usuario.cargo.nome ? _vm.usuario.cargo.nome : "Não possui")+" ")])])],1)]),_c(VExpandTransition,[_c(VCardText,{directives:[{name:"show",rawName:"v-show",value:(_vm.trueDetails),expression:"trueDetails"}]},[_c('h2',{staticClass:"text-xl font-weight-semibold mb-2"},[_vm._v("Detalhes")]),_c(VDivider),_c(VList,[_c(VListItem,{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v("Nome:")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.usuario.nome))])]),_c(VListItem,{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v("Filial:")]),(
                  _vm.usuario.relacionamento &&
                  _vm.usuario.relacionamento.filial &&
                  _vm.usuario.relacionamento.filial.nome
                )?_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.usuario.relacionamento.filial.nome)+" ")]):_c(VChip,{attrs:{"small":"","color":"warning"}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-information-outline")]),_vm._v(" Sem filial ")],1)],1),_c(VListItem,{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v("Setor:")]),(
                  _vm.usuario.relacionamento &&
                  _vm.usuario.relacionamento.setor &&
                  _vm.usuario.relacionamento.setor.nome
                )?_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.usuario.relacionamento.setor.nome)+" ")]):_c(VChip,{attrs:{"small":"","color":"warning"}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-information-outline")]),_vm._v(" Sem setor ")],1)],1),_c(VListItem,{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v("Cargo:")]),(
                  _vm.usuario.relacionamento &&
                  _vm.usuario.relacionamento.cargo &&
                  _vm.usuario.relacionamento.cargo.nome
                )?_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.usuario.relacionamento.cargo.nome)+" ")]):_c(VChip,{attrs:{"small":"","color":"warning"}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-information-outline")]),_vm._v(" Sem cargo ")],1)],1),_c(VListItem,{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v("Time:")]),(
                  _vm.usuario.relacionamento &&
                  _vm.usuario.relacionamento.time &&
                  _vm.usuario.relacionamento.time.nome
                )?_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.usuario.relacionamento.time.nome)+" ")]):_c(VChip,{attrs:{"small":"","color":"warning"}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-information-outline")]),_vm._v(" Sem time ")],1)],1),_c(VListItem,{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v("Gestor:")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.resolveUserGestor(_vm.usuario.gestor)))])]),_c(VListItem,{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v("Coordenador:")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.resolveUserCoordenandor(_vm.usuario.coordenador)))])]),_c(VListItem,{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium text-no-wrap me-2"},[_vm._v("E-mail:")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.usuario.email))])]),_c(VListItem,{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v("Status:")]),_c('span',{staticClass:"text--secondary"},[_c(VChip,{class:("v-chip-light-bg " + (_vm.resolveUserStatusVariant(
                    _vm.usuario.status
                  )) + "--text font-weight-medium text-capitalize"),attrs:{"small":"","label":"","color":_vm.resolveUserStatusVariant(_vm.usuario.status)}},[_vm._v(" "+_vm._s(_vm.usuario.status == "A" ? "Ativo" : "Inativo")+" ")])],1)]),_c(VListItem,{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v("Papel:")]),_c('span',{staticClass:"text--secondary text-capitalize"},[_vm._v(_vm._s(_vm.usuario.papel))])]),_c(VListItem,{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v("Uid:")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.usuario.uuid))])]),_c(VListItem,{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v("Celular:")]),(_vm.usuario.celular)?_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.usuario.celular))]):_c(VChip,{attrs:{"small":"","color":"warning"}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-information-outline")]),_vm._v(" Sem celular ")],1)],1),_c(VListItem,{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v("Data nascimento:")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm._f("dateFormatBr")(_vm.usuario.aniversario)))])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }