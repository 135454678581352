<template>
  <v-dialog v-model="dialog" persistent max-width="1300px">
    <v-card>
      <v-card-title style="justify-content: space-between">
        <h3>Perfil do Usuário</h3>
        <v-btn icon @click="fecharModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="5" lg="4">
            <UserBioPanel
              :userData="usuario"
              :trueDetails="tabs[userTab].id == 1 ? false : true"
            ></UserBioPanel>
          </v-col>
          <v-col cols="12" md="7" lg="8">
            <v-tabs v-model="userTab" show-arrows class="user-tabs">
              <v-tab v-for="tab in tabs" :key="tab.id">
                <v-icon size="20" class="me-3">
                  {{ tab.icon }}
                </v-icon>
                <span>{{ tab.title }}</span>
              </v-tab>
            </v-tabs>

            <v-tabs-items
              id="user-tabs-content"
              v-model="userTab"
              class="mt-5 pa-1"
            >
              <v-tab-item v-for="tab in tabs" :key="tab.id">
                <component :is="tab.component"></component>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import UserBioPanel from "@/modules/administrativo/modules/empresas/components/usuario/user-bio-panel/UserBioPanel.vue";
const UserTabOverview = () =>
  import(
    "@/modules/administrativo/modules/empresas/components/usuario/user-tab-overview/UserTabOverview.vue"
  );
const UserTabSecurity = () =>
  import(
    "@/modules/administrativo/modules/empresas/components/usuario/user-tab-security/UserTabSecurity.vue"
  );

export default {
  name: "ModalUsuario",
  components: {
    UserBioPanel,
  },
  props: {
    dialog: {
      require: true,
      default: false,
    },
  },

  data() {
    return {
      tabPerfil: null,
      tabs: [
        {
          id: 1,
          icon: "mdi-account-outline",
          title: "Visão geral",
          component: UserTabOverview,
        },
        {
          id: 2,
          icon: "mdi-lock-outline",
          title: "Segurança",
          component: UserTabSecurity,
        },
        { id: 3, icon: "mdi-bookmark-outline", title: "Profissional" },
        { id: 4, icon: "mdi-bell-outline", title: "Notificações" },
        { id: 5, icon: "mdi-link-variant", title: "Aplicativos conectados" },
      ],
      userTab: 0,
    };
  },

  methods: {
    fecharModal() {
      this.$emit("close-modal");
    },
    setColor(status) {
      if (status == "A") {
        return "green";
      } else {
        return "red";
      }
    },
    atualizarImagem(foto) {
      this.usuario = { propriedade: "foto", valor: foto };
      this.$emit("change", foto);
    },
  },

  computed: {
    usuario: {
      get() {
        return this.$store.getters["usuarios/getUsuario"];
      },
    },
  },
};
</script>

<style></style>
