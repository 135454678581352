<template>
  <section name="usuarios">
    <v-row>
      <v-col cols="12">
        <Crud
          title="Gestão de Usuários"
          buttonAddItemText="Novo Usuário"
          searchLabelText="Pesquisar na lista"
          :searchTextField.sync="searchTextField"
          :formTitle="nameForm"
          :callBackModalCancelar="fechar"
          :dialog.sync="dialog"
          :callBackModalSalvar="salvar"
          :dialogDelete.sync="dialogDelete"
        >
          <template slot="data-table">
            <v-data-table
              :headers="headers"
              :items="usuarios"
              :loading="loadingTable"
            >
              <template v-slot:[`item.nome`]="{ item }">
                <div class="d-flex align-center">
                  <v-avatar
                    :color="item.foto ? '' : 'primary'"
                    :class="item.foto ? '' : 'v-avatar-light-bg primary--text'"
                    size="32"
                  >
                    <v-img v-if="item.foto" :src="item.foto"></v-img>
                    <span v-else class="font-weight-medium">{{
                      avatarText(item.nome)
                    }}</span>
                  </v-avatar>

                  <div class="d-flex flex-column ms-3">
                    {{ item.nome }}

                    <small>{{ item.email }}</small>
                  </div>
                </div>
              </template>
              <template v-slot:[`item.gestor`]="{ item }">
                <v-chip :color="item.gestor == 1 ? 'accent' : 'secondary'">
                  {{ item.gestor == 1 ? "Sim" : "Não" }}
                </v-chip>
              </template>
              <template v-slot:[`item.coordenador`]="{ item }">
                <v-chip :color="item.coordenador == 1 ? 'accent' : 'secondary'">
                  {{ item.coordenador == 1 ? "Sim" : "Não" }}
                </v-chip>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-switch
                  class="mt-0 pt-0"
                  v-model="item.status"
                  @click="mudarStatus(item)"
                  :label="item.status == 'A' ? 'Ativo' : 'Inativo'"
                  color="success"
                  true-value="A"
                  false-value="I"
                  hide-details
                ></v-switch>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ item.created_at | dateFormatBr() }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <!-- Visualizar -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon
                                class="actions"
                                @click="openViewerModal(item)"
                              >
                                mdi-eye
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Visualizar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>

                    <!-- Editar -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon class="actions" @click="editar(item)">
                                mdi-pencil
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>

                    <!-- Deletar -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon
                                class="actions"
                                @click="remover(item, true)"
                              >
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Deletar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </template>
          <!--------------- MODAL DE DELETE --------------------------------------------- -->
          <template slot="modalDelete/titleModal">
            Excluir o registro?
          </template>
          <template slot="modalDelete/contentModal">
            <p>
              Atenção! Você está prestes a realizar uma ação que não pode ser
              desfeita.
            </p>
            <p>Você realmente deseja excluir esse registro?</p>
          </template>
          <template slot="modalDelete/actionsModal">
            <v-btn depressed @click="dialogDelete = false">Fechar</v-btn>
            <v-btn
              :loading="loadingButtonDelete"
              color="error"
              @click="remover()"
            >
              Excluir
            </v-btn>
            <v-spacer class="d-none d-md-block"></v-spacer>
          </template>
          <!-- ------------- MODAL DE CADASTRO --------------------------------------------- -->
          <template slot="contentModalCrud">
            <UsuariosForm />
          </template>
        </Crud>
      </v-col>
    </v-row>
    <ModalUsuario
      :dialog="dialogViewerModal"
      @close-modal="closeViewerModal()"
    />
  </section>
</template>

<script>
import ModalUsuario from "./usuario/ModalUsuario.vue";
import Crud from "@/components/Crud.vue";
import UsuariosForm from "./forms/UsuariosForm.vue";
import formData from "form-data";

export default {
  name: "UsuariosView",
  inject: ["$validator"],
  components: {
    ModalUsuario,
    Crud,
    UsuariosForm,
  },
  data: () => ({
    nameForm: "Novo Usuário",
    searchTextField: "",
    dialog: false,
    loadingTable: false,
    headers: [
      { text: "Nome", value: "nome" },
      { text: "Gestor", value: "gestor" },
      { text: "Coordenador", value: "coordenador" },
      { text: "Status", value: "status" },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],

    // Data ModalDelete
    dialogDelete: false,
    loadingButtonDelete: false,
    item: [],

    // ViewerModal
    dialogViewerModal: false,

    dataSavePhotoUser: {},
  }),

  computed: {
    usuarios: {
      get() {
        return this.$store.getters["usuarios/getUsuarios"];
      },
    },
    usuario: {
      get() {
        return this.$store.getters["usuarios/getUsuario"];
      },
      set(value) {
        this.$store.dispatch("usuarios/setUsuario", value);
      },
    },
  },

  created() {
    this.loadingTable = true;
    const uuidCompany = this.$route.params.uuid;

    this.$store
      .dispatch("usuarios/initializeByCompany", uuidCompany)
      .finally(() => {
        this.loadingTable = false;
      });
  },

  methods: {
    salvar() {
      this.$validator.validate("adm_usuarios.*").then((result) => {
        if (result) {
          this.dialog = false;
          this.loadingTable = true;

          const isEdit = this.usuario.id || this.usuario.uuid ? true : false;
          const messageSave = isEdit
            ? "Usuário atualizado com sucesso"
            : "Usuário criado com sucesso";
          const messageCatch = isEdit
            ? "Houve um erro ao atualizar o usuário"
            : "Houve um erro ao criar o usuário";

          let usuarioItem = {};
          Object.assign(usuarioItem, this.usuario);
          usuarioItem._method = isEdit ? "PUT" : "POST";
          usuarioItem.url = isEdit
            ? `/usuarios/${usuarioItem.uuid}`
            : "/usuarios";

          isEdit == false ? (usuarioItem.foto = "/avatar/users.png") : null;

          delete usuarioItem.senha;

          this.$store
            .dispatch("usuarios/save", usuarioItem)
            .then((response) => {
              if (isEdit) {
                const index = this.usuarios.findIndex(
                  (el) => el.id == usuarioItem.id
                );

                Object.assign(this.usuarios[index], usuarioItem);
                this.dataSavePhotoUser = usuarioItem;
              } else {
                this.usuarios.push(response);
                this.dataSavePhotoUser = response;
              }

              this.savePhotoUser();

              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                mensagem: messageSave,
                cor: "green",
              });
            })
            .catch((error) => {
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                mensagem: messageCatch,
                cor: "red",
              });
            });
        }
      });
    },
    editar(item) {
      this.loadingTable = true;

      this.$store
        .dispatch("usuarios/findByUuid", item.uuid)
        .then((response) => {
          this.usuario = Object.assign({}, response);
          this.nameForm = "Editar usuário";
          this.dialog = true;
        })
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Houve um erro ao buscar pelo usuário",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    fechar() {
      this.nameForm = "Novo usuário";
      this.loadingTable = false;
      this.dialog = false;
      this.usuario = {
        nome: "",
        email: "",
        foto: null,
        id_papel: null,
        aniversario: null,
        gestor: 0,
        coordenador: 0,
        senha: "",
        endereco: {
          cep: "",
          rua: "",
          numero: 0,
          bairro: "",
          complemento: "",
          uf: "",
          cidade: "",
        },
        relacionamento: {
          id_filial: null,
          id_setor: null,
          id_cargo: null,
          id_time: null,
        },
      };
    },
    remover(item, openModal = false) {
      if (openModal) {
        this.item = Object.assign({}, item);
        this.dialogDelete = true;
      } else {
        this.loadingButtonDelete = true;
        const indexItem = this.usuarios.findIndex(
          (el) => el.id == this.item.id
        );

        this.$store
          .dispatch("usuarios/remove", this.item.uuid)
          .then((response) => {
            if (response) {
              this.usuarios.splice(indexItem, 1);

              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: "Usuário removido.",
              });
              this.item = {};
            }
          })
          .catch(() => {
            this.$store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Não foi possível remover o usuário.",
            });
          })
          .finally(() => {
            this.loadingButtonDelete = false;
            this.dialogDelete = false;
          });
      }
    },
    mudarStatus(item) {
      this.loadingTable = true;

      let usuarioItem = {
        status: item.status,
        _method: "PUT",
        url: `/usuarios/${item.uuid}`,
      };

      this.$store
        .dispatch("usuarios/save", usuarioItem)
        .then((response) => {
          if (response != "NAO_AUTORIZADO") {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem: "Status atualizado com sucesso.",
              cor: "green",
            });
          }
        })
        .catch((error) => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Erro ao atualizar o status.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    openViewerModal(item) {
      this.loadingTable = true;

      this.$store
        .dispatch("usuarios/findByUuid", item.uuid)
        .then((response) => {
          this.usuario = response;
          this.dialogViewerModal = true;
        })
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem:
              "Houve um erro ao buscar os dados do usuário. Por favor, tente novamente mais tarde.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    closeViewerModal() {
      this.dialogViewerModal = false;
      this.usuario = {
        nome: "",
        email: "",
        foto: null,
        id_papel: null,
        aniversario: null,
        gestor: 0,
        coordenador: 0,
        senha: "",
        endereco: {
          cep: "",
          rua: "",
          numero: 0,
          bairro: "",
          complemento: "",
          uf: "",
          cidade: "",
        },
        relacionamento: {
          id_filial: null,
          id_setor: null,
          id_cargo: null,
          id_time: null,
        },
      };
    },
    /**
     * @description Salva a imagem do usuário e retorna a url para o mesmo.
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    savePhotoUser() {
      if (
        this.usuario.foto &&
        this.usuario.foto !== null &&
        typeof this.usuario.foto !== "string"
      ) {
        let form = new formData();
        form.append("id_usuario", this.dataSavePhotoUser.id);
        form.append("foto", this.usuario.foto);

        this.$store
          .dispatch("usuarios/savePhotoUser", form)
          .then((response) => {
            const index = this.usuarios.findIndex(
              (el) => el.id == this.dataSavePhotoUser.id
            );
            this.usuarios[index].foto = response;
          })
          .catch(() => {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem: "Houve um erro ao salvar a foto do usuário.",
              cor: "red",
            });
          })
          .finally(() => {
            this.dataSavePhotoUser = {};
            this.loadingTable = false;
            this.fechar();
          });
      } else {
        this.loadingTable = false;
        this.fechar();
      }

      return this;
    },
  },
};
</script>

<style scoped></style>
