import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"name":"usuarios"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('Crud',{attrs:{"title":"Gestão de Usuários","buttonAddItemText":"Novo Usuário","searchLabelText":"Pesquisar na lista","searchTextField":_vm.searchTextField,"formTitle":_vm.nameForm,"callBackModalCancelar":_vm.fechar,"dialog":_vm.dialog,"callBackModalSalvar":_vm.salvar,"dialogDelete":_vm.dialogDelete},on:{"update:searchTextField":function($event){_vm.searchTextField=$event},"update:search-text-field":function($event){_vm.searchTextField=$event},"update:dialog":function($event){_vm.dialog=$event},"update:dialogDelete":function($event){_vm.dialogDelete=$event},"update:dialog-delete":function($event){_vm.dialogDelete=$event}}},[_c('template',{slot:"data-table"},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.usuarios,"loading":_vm.loadingTable},scopedSlots:_vm._u([{key:"item.nome",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{class:item.foto ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.foto ? '' : 'primary',"size":"32"}},[(item.foto)?_c(VImg,{attrs:{"src":item.foto}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.nome)))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_vm._v(" "+_vm._s(item.nome)+" "),_c('small',[_vm._v(_vm._s(item.email))])])],1)]}},{key:"item.gestor",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":item.gestor == 1 ? 'accent' : 'secondary'}},[_vm._v(" "+_vm._s(item.gestor == 1 ? "Sim" : "Não")+" ")])]}},{key:"item.coordenador",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":item.coordenador == 1 ? 'accent' : 'secondary'}},[_vm._v(" "+_vm._s(item.coordenador == 1 ? "Sim" : "Não")+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VSwitch,{staticClass:"mt-0 pt-0",attrs:{"label":item.status == 'A' ? 'Ativo' : 'Inativo',"color":"success","true-value":"A","false-value":"I","hide-details":""},on:{"click":function($event){return _vm.mudarStatus(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormatBr")(item.created_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c(VList,[_c(VListItem,[_c(VListItemTitle,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"actions",on:{"click":function($event){return _vm.openViewerModal(item)}}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar")])])],1)],1),_c(VListItem,[_c(VListItemTitle,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"actions",on:{"click":function($event){return _vm.editar(item)}}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1)],1),_c(VListItem,[_c(VListItemTitle,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"actions",on:{"click":function($event){return _vm.remover(item, true)}}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Deletar")])])],1)],1)],1)],1)]}}],null,true)})],1),_c('template',{slot:"modalDelete/titleModal"},[_vm._v(" Excluir o registro? ")]),_c('template',{slot:"modalDelete/contentModal"},[_c('p',[_vm._v(" Atenção! Você está prestes a realizar uma ação que não pode ser desfeita. ")]),_c('p',[_vm._v("Você realmente deseja excluir esse registro?")])]),_c('template',{slot:"modalDelete/actionsModal"},[_c(VBtn,{attrs:{"depressed":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Fechar")]),_c(VBtn,{attrs:{"loading":_vm.loadingButtonDelete,"color":"error"},on:{"click":function($event){return _vm.remover()}}},[_vm._v(" Excluir ")]),_c(VSpacer,{staticClass:"d-none d-md-block"})],1),_c('template',{slot:"contentModalCrud"},[_c('UsuariosForm')],1)],2)],1)],1),_c('ModalUsuario',{attrs:{"dialog":_vm.dialogViewerModal},on:{"close-modal":function($event){return _vm.closeViewerModal()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }