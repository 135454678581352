import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"1300px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticStyle:{"justify-content":"space-between"}},[_c('h3',[_vm._v("Perfil do Usuário")]),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.fecharModal()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"5","lg":"4"}},[_c('UserBioPanel',{attrs:{"userData":_vm.usuario,"trueDetails":_vm.tabs[_vm.userTab].id == 1 ? false : true}})],1),_c(VCol,{attrs:{"cols":"12","md":"7","lg":"8"}},[_c(VTabs,{staticClass:"user-tabs",attrs:{"show-arrows":""},model:{value:(_vm.userTab),callback:function ($$v) {_vm.userTab=$$v},expression:"userTab"}},_vm._l((_vm.tabs),function(tab){return _c(VTab,{key:tab.id},[_c(VIcon,{staticClass:"me-3",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(tab.icon)+" ")]),_c('span',[_vm._v(_vm._s(tab.title))])],1)}),1),_c(VTabsItems,{staticClass:"mt-5 pa-1",attrs:{"id":"user-tabs-content"},model:{value:(_vm.userTab),callback:function ($$v) {_vm.userTab=$$v},expression:"userTab"}},_vm._l((_vm.tabs),function(tab){return _c(VTabItem,{key:tab.id},[_c(tab.component,{tag:"component"})],1)}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }